<template>
    <div>
        
         <b-navbar sticky class="app-navbar-profile">
            <div class="app-navbar-header">
                <b-row class="align-items-center mb-3 ml-1 mr-1">
                    <b-col cols="8"> 
                        <div class="app-local-name">{{trans('settings',280)}}</div>
                    </b-col>        
                </b-row> 
            </div>
        </b-navbar>

        <div>       
            <b-row class="ml-3 mr-3">
                <b-col>
                    <b-card no-body class="app-card-profile" >
                            <clients-managment-change-password />
                    </b-card>
            
                </b-col>
            </b-row>

        </div>

    </div>
</template>

<script>
import axios from "@axios";
import moment from "moment";
import ClientsManagmentChangePassword from './ClientsManagmentChangePassword.vue'


export default {
    components: {
       ClientsManagmentChangePassword,
    },
    
    mounted() {
      
    },

    data() {
        return {
            
        };
    },
    
    methods: {

       
    },
};
</script>

<style scoped>
.app-local-name{
    font-size:24px;
    font-weight: 600;
}

.app-navbar-header{
    width:100%;
}

</style>
