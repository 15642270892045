<template>
    <div class="mx-2 my-4">
       

    <div class="app-local-name mb-3">{{trans('auth-change-password',277)}}</div>
        <b-row>
            <b-col>
                <ValidationObserver ref="form">
                    <app-password id="old_pass" class="app-local-inputs" v-model="old_password" validatorRules="required" :validatorName="trans('auth-password',278)" :label="trans('cp-enter-current-password',290)"/>
                    <app-input v-model="error2" class="mb-3 app-local-inputs" type="hidden" validatorRules="required" :validatorCustomMessage="{ required: trans('cp-entered-password-incorrect',289)}"/>

                    <div >
                        <div class="mb-3">
                            <app-password id="new_pass" :currentError="error3 != 'false'" class="app-local-inputs" v-model="new_password" :validatorName="trans('cp-new-password',289)" :label="trans('cp-enter-new-password',290)"/>
                            <ValidationObserver ref="custom">

                                <app-input v-model="error3" type="hidden" class="app-local-inputs" validatorRules="required" :validatorCustomMessage="{ required: trans('auth-password-same-as-before-used',279)}"/>

                            </ValidationObserver>
                        </div>
                        <app-password id="repeat_new_pass" class="app-local-inputs" v-model="password_confirmation" :validatorName="trans('cp-confirm-new-password',286)" :label="trans('cp-confirm-new-password',286)"/>
                        <ValidationObserver ref="custom">
                            <app-input v-model="error" type="hidden" class="app-local-inputs" validatorRules="required" :validatorCustomMessage="{ required: trans('cp-passwords-are-not-identical',289) }"/>
                            
                        </ValidationObserver>
                    </div>
                </ValidationObserver>
                <div class="mt-4" v-if="success">
                    <span class="app-local-success">{{trans('cp-password-has-been-changed',289)}}</span>
                </div>
                <div class="text-center app-local-inputs" v-if="!success">
                   <app-button type="primary" class="my-4" :loading="loading" :block="true" size="md" @click="changePassword">{{trans('save',282)}}</app-button>
                </div>
            </b-col>
           
        </b-row>
          
    </div>
</template>
<script>
import AppPassword from "@core/components/AppPassword.vue"
import {ValidationObserver} from "vee-validate";
import axios from "@axios";

export default {
    components: {
        AppPassword,
        ValidationObserver
    },

    watch: {
        "new_password":{
            handler: function(val) {
                if(val != undefined &&  this.error == ''){
                  this.error= 'false';
                  this.$refs.custom.validate();
                }else if(val != undefined &&  this.error3 == ''){
                    this.error3= 'false';
                    this.$refs.custom.validate();
                }
                
            }
        },
        "password_confirmation":{
            handler: function(val) {
                if(val != undefined &&  this.error == ''){
                  this.error= 'false';
                  this.$refs.custom.validate();
                }else if(val != undefined &&  this.error3 == ''){
                    this.error3= 'false';
                    this.$refs.custom.validate();
                }
                
            },
        },
        "old_password":{
            handler: function(val) {
                if(val != undefined &&  this.error2 == ''){
                  this.error2= 'false';
                  this.$refs.custom.validate();
                }
                
            },
        }
    },

    data(){
        return{
            old_password: null,
            new_password: null,
            password_confirmation: null,
            error: 'false',
            error2: 'false',
            error3: 'false',
            success: false,
            loading: false,
        }
    },

    methods:{       
        changePassword() {
          
            this.$refs.form.validate().then(async (result) => {
            if (!result) {
                return false;
            }else{

                    if(this.password_confirmation != this.new_password){
                        this.error='';
                        await this.$nextTick();
                        this.$refs.custom.validate()
                        return;
                    }else if(this.old_password == this.new_password){
                        this.error3='';
                        await this.$nextTick();
                        this.$refs.custom.validate()
                        return;
                    }
                    this.loading = true;

                    axios.post("clients/management/changePassword", {
                        old_password: this.old_password,
                        new_password: this.new_password,
                    }).then((res) => {
                        if(res.data == -1){
                            this.error2='';
                        }else if(res.data == -2){
                            this.error3='';
                        }else{
                            this.success = true;
                        }
                    })
                    .catch((error) => {
                            console.error(`error during request: ${error}`);
            
                    }).finally(() => {
                        this.loading = false;
                    }); 
                }
            });
        }
    }
 
};
</script>

<style>
.app-local-inputs .app-input-error-ellips {
    overflow: initial !important;
    color: #d92d20 !important;;
    white-space: break-spaces;
}
</style>
<style scoped>
.app-local-inputs{
    min-width:200px;
    max-width:350px;
}
.app-local-name{
    font-size:16px;
    font-weight: 800;
}
.app-local-success{
    font-size:12px;
    font-weight: 800;
    color: #3b9d75;
    background-color: #edfdf3;
    padding:8px;
}
</style>